var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 推荐配置 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-button',{staticClass:"all_boder_btn",on:{"click":_vm.relationFilling}},[_vm._v("新建")])]},proxy:true}])}),_c('a-table',{attrs:{"rowKey":function (item) { return item.id; },"columns":_vm.columnss,"data-source":_vm.tableData,"pagination":{
      total: _vm.queryParams.total,
      current: _vm.queryParams.pageNum,
      defaultPageSize: _vm.queryParams.pageSize,
      showSizeChanger: true,
      showTotal: function (total, range) {
        return ("共" + total + "条");
      },
    }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.queryParams.pageNum - 1) * _vm.queryParams.pageSize + i + 1)+" ")])]}},{key:"operation",fn:function(item, row, index){return [_c('div',{staticClass:"btn_router_link"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(!(index == 0 && _vm.queryParams.pageNum == 1)),expression:"!(index == 0 && queryParams.pageNum == 1)"}],on:{"click":function($event){return _vm.onMove(row.id, '1')}}},[_vm._v("上移 | ")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(
            (_vm.queryParams.pageNum - 1) * _vm.queryParams.pageSize + index !=
            _vm.queryParams.total - 1
          ),expression:"\n            (queryParams.pageNum - 1) * queryParams.pageSize + index !=\n            queryParams.total - 1\n          "}],on:{"click":function($event){return _vm.onMove(row.id, '2')}}},[_vm._v("下移 | ")]),_c('a',{on:{"click":function($event){return _vm.deleteData(row.id)}}},[_vm._v("删除")])])]}}])}),_c('a-modal',{attrs:{"width":"800px","title":"选择商品","centered":true,"closable":false},model:{value:(_vm.relation),callback:function ($$v) {_vm.relation=$$v},expression:"relation"}},[_c('div',{staticClass:"search-box"},[_c('div',{staticClass:"left"},[_c('a-cascader',{staticClass:"search-input",attrs:{"options":JSON.parse(JSON.stringify(_vm.cgoodList)) || [],"fieldNames":{ label: 'name', value: 'id', children: 'children' },"placeholder":"请选择类型"},on:{"change":_vm.onChange}}),_c('a-input',{staticClass:"search-input",attrs:{"placeholder":"请输入商品名称"},model:{value:(_vm.productForm.name),callback:function ($$v) {_vm.$set(_vm.productForm, "name", $$v)},expression:"productForm.name"}})],1),_c('a-button',{staticClass:"search-btn",attrs:{"type":"primary"},on:{"click":_vm.onProductSearch}},[_vm._v("搜索")])],1),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template1",attrs:{"row-selection":{
          columnTitle: '选择',
          selectedRowKeys: _vm.selectedProductRowKeys,
          onChange: _vm.onSelectProduct,
          type: 'radio',
          columnWidth: '70px',
        },"bordered":"","rowKey":function (item) { return item.id; },"columns":_vm.productcolumns,"data-source":_vm.productData,"loading":_vm.productLoad,"pagination":{
          total: _vm.productTotal,
          current: _vm.productForm.page,
          defaultPageSize: _vm.productForm.size,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return ("共" + total + "条");
          },
        }},on:{"change":_vm.onProductPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s((_vm.productForm.page - 1) * _vm.productForm.size + index + 1)+" ")]}},{key:"productName",fn:function(item, row){return [_vm._v(" "+_vm._s(row.productName)+_vm._s(row.name)+" ")]}}])})],1),_c('template',{slot:"footer"},[_c('a-button',{attrs:{"type":"primary","loading":_vm.btnLoad},on:{"click":_vm.relationOk}},[_vm._v("确认")]),_c('a-button',{on:{"click":_vm.relationCancel}},[_vm._v("取消")])],1)],2),_c('a-modal',{attrs:{"width":"530px","title":"删除","centered":true,"closable":false},on:{"ok":_vm.delateOk},model:{value:(_vm.delate),callback:function ($$v) {_vm.delate=$$v},expression:"delate"}},[_c('div',{staticClass:"tips"},[_vm._v(" 删除该配置后，首页热门课程将不会有该内容，是否继续删除？ ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }